import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getCliamFiles = async (token, limit, offset) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/claim_files?limit=${limit}&offset=${offset}`,
    config
  );
  return response;
};

const getCliamFile = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/claim_files/${id}`,
    config
  );
  return response;
};

const searchCliamFile = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/claim_files/${id}`,
    config
  );
  return response;
};

export default {
  getCliamFiles,
  getCliamFile,
  searchCliamFile,
};
