import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import claimFileService from "../../../services/claimFileService";
import { Document, Page, pdfjs } from "react-pdf";

import {
  Container,
  Card,
  Grid,
  Box,
  Typography,
  Button,
  Breadcrumbs,
} from "@mui/material";
import { instanceToken } from "../../../utils/constant";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ClaimFile = ({ homeAlert }) => {
  const { insurance_id } = useParams();
  console.log(insurance_id);
  const [claimFiles, setClaimFiles] = useState(null);

  useEffect(() => {
    if (!claimFiles) {
      fetchClaimfiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchClaimfiles = async () => {
    try {
      const res = await claimFileService.getCliamFile(
        instanceToken.token,
        insurance_id
      );
      console.log(res.data);
      setClaimFiles(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  if (!claimFiles) {
    return <em>Loading...</em>;
  }
  // const handleOpenR = () => setOpenR(true);
  // const handleCloseR = () => setOpenR(false);
  // const handleRemove = async () => {
  //   try {
  //     axios.delete(`https://cblife.axra.app/dashboard/api/claim_files/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${instanceToken.token}`,
  //       },
  //     });
  //     homeAlert("New ClaimForm post have been removed.", false);
  //     navigate("/banners");
  //   } catch (error) {
  //     console.log(error);
  //     homeAlert("Error on server!", true);
  //   }
  // };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/claimFiles">
            ClaimFiles
          </Link>
          <Typography color="text.primary">
            ClaimFiles (ID - {insurance_id})
          </Typography>
        </Breadcrumbs>
      </div>

      {claimFiles.map((row) => (
        <Container
          maxWidth={false}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Card
            sx={{
              minWidth: "80vw",
              minHeight: "60vh",
              display: "grid",
              gridTemplateColumns: "2fr 3fr",
            }}
          >
            <Box
              sx={{
                ml: 2,
                p: 2,
                // bgcolor: "#f7f7f5",
                borderRadius: 2,
              }}
            >
              <Grid sx={{ m: 2 }} container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Insurance ID :
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2" color="text.secondary">
                    {row?.insurance_id}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2 }} container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Files :
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  {/* <Typography variant="body2" color="text.secondary">
                    {row.file_path}
                  </Typography> */}
                  {/* <div style={{ width: "100%", height: "100vh" }}>
                    <iframe
                      src={row.file_path}
                      alt={row.name}
                      title="PDF Viewer"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div> */}
                  {row?.file_path && (
                    <Document
                      file={row.file_path}
                      onLoadError={(error) =>
                        console.error("Error loading PDF: ", error)
                      }
                    >
                      <Page pageNumber={1} />
                    </Document>
                  )}
                </Grid>
              </Grid>
              <Grid sx={{ m: 2 }} container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Name :
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2" color="text.secondary">
                    {row.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2 }} container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Type :
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2" color="text.secondary">
                    {row.type}
                  </Typography>
                </Grid>
              </Grid>
              {row.type === "application/pdf" && (
                <Button
                  variant="contained"
                  style={{ marginLeft: 30, marginTop: 20 }}
                >
                  {" "}
                  <a
                    href={`${row.file_path}`}
                    download={row.name}
                    className="downloadTag"
                  >
                    Download file
                  </a>
                </Button>
              )}
              {row.type === "image/jpeg" && (
                <Button
                  variant="contained"
                  style={{ marginLeft: 30, marginTop: 20 }}
                >
                  <a
                    href={`${row.file_path}`}
                    download={row.name}
                    className="downloadTag"
                  >
                    Download file
                  </a>
                </Button>
              )}
              {row.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                <Button
                  variant="contained"
                  style={{ marginLeft: 30, marginTop: 20 }}
                >
                  <a
                    href={`${row.file_path}`}
                    download={row.name}
                    className="downloadTag"
                  >
                    Download file
                  </a>
                </Button>
              )}
            </Box>

            {/* <CardActions sx={{ gridColumn: "1/3", justifyContent: "end" }}>
                <Button size="small" color="error" onClick={handleOpenR}>
                  Remove
                </Button>
              </CardActions> */}
          </Card>
        </Container>
      ))}

      {/* <Modal
        keepMounted
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button onClick={handleRemove}>Confirm</Button>
          </Box>
        </Box>
      </Modal> */}
    </>
  );
};

export default ClaimFile;
