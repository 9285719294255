import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import uploadService from "../../../services/upload";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  Breadcrumbs,
  FormControl,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  Button,
  Typography,
  CardMedia,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import RichTextEditor from "react-rte";
import { instanceToken } from "../../../utils/constant";
import axios from "axios";
import emergencieService from "../../../services/emergencieService";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];

export default function UpdateEmergencies({ homeAlert }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isImageChange, setIsImageChange] = useState(false);

  const [nameEng, setNameEng] = useState("");
  const [nameMM, setNameMM] = useState("");
  const [addressEng, setAddressEng] = useState("");
  const [addressMM, setAddressMM] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneNote, setPhoneNote] = useState("");
  const [category, setCategory] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState("");
  const [oldImageName, setOldImageName] = useState();
  const [errors, setErrors] = useState({});

  const [emergencies, setEmergencies] = useState(null);
  useEffect(() => {
    if (!emergencies) {
      fetchEmergencies();
    }
  }, []);

  useEffect(() => {
    if (emergencies) {
      setNameEng(emergencies?.name_en);
      setNameMM(emergencies?.name_mm);
      setAddressEng(emergencies?.address_en);
      setAddressMM(emergencies?.address_mm);
      setLink(emergencies?.link);
      setPhone(emergencies?.phone);
      setPhoneNote(emergencies?.phone_note);
      setCategory(emergencies?.category);
      setImage(emergencies.image_data);
      setPreview(`data:image/jpeg;base64,${emergencies?.image_data}`);

      if (emergencies.image_data) {
        setOldImageName(
          emergencies.image_data.substring(
            emergencies.image_data.lastIndexOf("/") + 1,
            emergencies.image_data.lenght
          )
        );
      }
    }
  }, [emergencies]);

  const fetchEmergencies = async () => {
    try {
      const res = await emergencieService.getOneEmergencies(
        instanceToken.token,
        id
      );
      setEmergencies(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };
  const changeNameEng = (e) => {
    setNameEng(e.target.value);
  };
  const changeNameMM = (e) => {
    setNameMM(e.target.value);
  };
  const changeAddressEng = (e) => {
    setAddressEng(e.target.value);
  };
  const changeAddressMM = (e) => {
    setAddressMM(e.target.value);
  };
  const changePhone = (e) => {
    setPhone(e.target.value);
  };
  const changePhoneNote = (e) => {
    setPhoneNote(e.target.value);
  };
  const changeLink = (e) => {
    setLink(e.target.value);
  };

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files && e.target.files[0]) {
        const img = e.target.files[0];
        if (!imgFileTypes.includes(img.type)) {
          setErrors({
            ...errors,
            image_data: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
          });
          return;
        }
        if (img.size > 204800) {
          setErrors({
            ...errors,
            image_data: "Image file size must be smaller than 200KB.",
          });
          return;
        }
        setPreview(URL.createObjectURL(img));
        setImage(img);
        setIsImageChange(true);
      }
    }
  };

  const handleUpdate = async () => {
    const formData = new FormData();
    formData.append("name_en", nameEng);
    formData.append("name_mm", nameMM);
    formData.append("address_en", addressEng);
    formData.append("address_mm", addressMM);
    formData.append("phone", phone);
    formData.append("phone_note", phoneNote);
    formData.append("link", link);
    formData.append("category", category);
    formData.append("image_data", image);

    setErrors({});
    let err = {};

    formData.forEach((value, index) => {
      if (value === "") {
        err[index] = `${index} field is required`;
      }
    });

    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      const response = await emergencieService.putEmergencies(
        instanceToken.token,
        id,
        formData
      );
      navigate("/emergencies");
      setEmergencies(response.data);
      homeAlert("Emergencies have been updated.", false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server.", true);
    } finally {
      setLoading(false);
      setErrors({});
    }
  };
  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/emergencies">Emergencies</Link>
          <span>Update Emergencies</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          <CardContent sx={{ display: "flex", pb: 0, flexDirection: "column" }}>
            <Box
              sx={{
                maxWidth: "40vw",
                display: "grid",
                justifyContent: "center",
                // bgcolor: "red",
                ml: 31,
                borderRadius: 2,
                boxShadow: 2,
              }}
            >
              <CardMedia
                component="img"
                height="320"
                image={preview}
                sx={{ my: 2 }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {/* image */}
              <FormControl sx={{ my: 2, maxWidth: 400 }}>
                <Typography
                  sx={{
                    mb: 1,
                    fontSize: "12px",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  Rendered size must be 340 * 180 px , Aspect ratio must be
                  1.8:1 and resolution must be 72ppi
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ p: 2 }}
                >
                  <PhotoCamera />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={changeFile}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                    error={errors["image_data"]}
                    FormHelperText={errors["image_data"]}
                  />
                </Button>
                <FormHelperText error>{errors["image_data"]}</FormHelperText>
              </FormControl>
            </Box>
            {/* name */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* name Eng */}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="name_en"
                  label="Name Eng"
                  value={nameEng}
                  onChange={changeNameEng}
                  error={errors["name_en"]}
                  helperText={errors["name_en"]}
                />
              </FormControl>
              {/* Nmae MM */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_mm"
                  label="Name MM"
                  value={nameMM}
                  onChange={changeNameMM}
                  error={errors["name_mm"]}
                  helperText={errors["name_mm"]}
                />
              </FormControl>
            </Box>
            {/* address */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* address Eng */}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="address_en"
                  label="Address Eng"
                  value={addressEng}
                  onChange={changeAddressEng}
                  error={errors["address_en"]}
                  helperText={errors["address_en"]}
                />
              </FormControl>
              {/* Address MM */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="address_mm"
                  label="Address MM"
                  value={addressMM}
                  onChange={changeAddressMM}
                  error={errors["address_mm"]}
                  helperText={errors["address_mm"]}
                />
              </FormControl>
            </Box>
            {/* address */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* phone*/}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="phone"
                  label="Phone"
                  value={phone}
                  onChange={changePhone}
                  error={errors["phone"]}
                  helperText={errors["phone"]}
                />
              </FormControl>
              {/* phoneNote */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="phone_note"
                  label="Phone Note"
                  value={phoneNote}
                  onChange={changePhoneNote}
                  error={errors["phone_note"]}
                  helperText={errors["phone_note"]}
                />
              </FormControl>

              {/* category */}
              {/* Category */}
              <FormControl sx={{ my: 2 }}>
                <InputLabel id="category">Category</InputLabel>
                <Select
                  labelId="category"
                  label="category"
                  defaultValue=""
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setErrors({});
                  }}
                  //error={errors.category ? true : false}
                >
                  <MenuItem value="" disabled>
                    Value
                  </MenuItem>
                  <MenuItem value="Emergency Hotline">
                    Emergency Hotline
                  </MenuItem>
                  <MenuItem value="Ambulance">Ambulance</MenuItem>
                  <MenuItem value="Oxygen">Oxygen</MenuItem>
                  <MenuItem value="Blood Donation">Blood Donation</MenuItem>
                </Select>
                {/* {errors.category && (
                  <FormHelperText error>{errors.category}</FormHelperText>
                )} */}
                <FormHelperText error>{errors["category"]}</FormHelperText>
              </FormControl>

              {/* link */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="link"
                  label="Link"
                  value={link}
                  onChange={changeLink}
                  error={errors["link"]}
                  helperText={errors["link"]}
                />
              </FormControl>
            </Box>
          </CardContent>

          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleUpdate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Update
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </>
  );
}
