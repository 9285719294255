import { useState } from "react";
import axios from "axios";
import RichTextEditor from "react-rte";
import { Link } from "react-router-dom";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { LoadingButton } from "@mui/lab";
import uploadService from "../../../services/upload";
import newService from "../../../services/newService";
// import * as Marked from 'marked';
import * as React from "react";
import {
  Card,
  CardContent,
  Select,
  MenuItem,
  CardActions,
  Box,
  Breadcrumbs,
  FormControl,
  InputLabel,
  TextField,
  FormHelperText,
  Button,
  Typography,
  CardMedia,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { instanceToken } from "../../../utils/constant";
const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export default function CreateNews({ homeAlert }) {
  const navigate = useNavigate();

  const [titleEng, setTitleEng] = useState("");
  const [titleMM, setTitleMM] = useState("");
  const [category, setCategory] = useState("");
  const [parEng, setParEng] = useState(RichTextEditor.createEmptyValue());
  const [parMM, setParMM] = useState(RichTextEditor.createEmptyValue());
  const [image, setImage] = useState("");
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [errors, setErrors] = useState({});

  const changeTitleMM = (e) => {
    setTitleMM(e.target.value.trim());
  };
  const changeTitleEng = (e) => {
    setTitleEng(e.target.value.trim());
  };

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      console.log("image data is ", img);
      if (!imgFileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          image_data: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 204800) {
        setErrors({
          ...errors,
          image_data: "Image file size must be smaller than 200KB.",
        });
        return;
      }
      setPreview(URL.createObjectURL(img));
      setImage(img);
    }
  };
  const formData = new FormData();

  const handleCreate = async (e) => {
    e.preventDefault();

    formData.append("title_en", titleEng);
    formData.append("title_mm", titleMM);
    formData.append("category", category);
    formData.append("paragraph_en", parEng.toString("html").trim());
    formData.append("paragraph_mm", parMM.toString("html").trim());
    formData.append("image_data", image);

    setErrors({});
    let err = {};
    formData.forEach((value, index) => {
      // console.log("value", value);
      if (value === "") {
        err[index] = `${index} field is required`;
      }
    });
    console.log("error ", err);
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }

    try {
      setLoading(true);

      const response = await newService.postNews(instanceToken.token, formData);
      navigate("/all_news");
      setNews(response.data);

      homeAlert("News Post have been created.", false);
      // setLoading(false);
    } catch (error) {
      console.log(error);
      homeAlert("File Upload Failed!", true);
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/news">News Post</Link>
          <span>Create News Post</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          {formData.forEach((value, index) => console.log(index))}
          <CardContent sx={{ display: "flex", pb: 0, flexDirection: "column" }}>
            <Box
              sx={{
                maxWidth: "40vw",
                display: "grid",
                justifyContent: "center",
                // bgcolor: "red",
                ml: 31,
                borderRadius: 2,
                boxShadow: 2,
              }}
            >
              <CardMedia
                component="img"
                height="320"
                image={preview}
                sx={{ my: 2 }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {/* image */}

              <FormControl sx={{ my: 2, maxWidth: 400 }}>
                <Typography
                  sx={{
                    mb: 1,
                    fontSize: "12px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  Rendered size must be 1920 * 1080 px and Aspect ratio must be
                  16:9
                </Typography>

                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ p: 2 }}
                >
                  <PhotoCamera />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={changeFile}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                  />
                </Button>
                <FormHelperText error>{errors["image_data"]}</FormHelperText>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* title Eng */}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="title_en"
                  label="Title Eng"
                  value={titleEng}
                  onChange={changeTitleEng}
                  error={errors["title_en"]}
                  helperText={errors["title_en"]}
                />
              </FormControl>
              {/* title MM */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="title_mm"
                  label="Title MM"
                  value={titleMM}
                  onChange={changeTitleMM}
                  error={errors["title_mm"]}
                  helperText={errors["title_mm"]}
                />
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* Category */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <InputLabel id="category">Category</InputLabel>
                <Select
                  labelId="category"
                  label="category"
                  defaultValue=""
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setErrors({});
                  }}
                  //error={errors.category ? true : false}
                >
                  <MenuItem value="" disabled>
                    Value
                  </MenuItem>
                  {/* <MenuItem value="Hospital_&_Clinic">
                    Hospital & Clinic
                  </MenuItem> */}
                  <MenuItem value="News">News</MenuItem>
                  <MenuItem value="Event">Event</MenuItem>
                  <MenuItem value="Promotion">Promotion</MenuItem>
                </Select>
                {/* {errors.category && (
                  <FormHelperText error>{errors.category}</FormHelperText>
                )} */}
                <FormHelperText error>{errors["category"]}</FormHelperText>
              </FormControl>
            </Box>

            <Box
              sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                px: 3,
              }}
            >
              {/* paragraph Eng */}
              <Box>
                <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Paragraph Eng
                </InputLabel>
                <RichTextEditor
                  onChange={setParEng}
                  value={parEng}
                  toolbarConfig={toolbarConfig}
                  error={errors["paragraph_en"]}
                  helperText={errors["paragraph_en"]}
                />
                {/* {errors.description && (
                  <FormHelperText error> {errors.description}</FormHelperText>
                )} */}
              </Box>

              {/* paragraph MM*/}
              <Box sx={{ my: 2 }}>
                <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Paragraph MM
                </InputLabel>
                <RichTextEditor
                  onChange={setParMM}
                  value={parMM}
                  toolbarConfig={toolbarConfig}
                  // error={errors["paragraph_mm"]}
                  // helperText={errors["paragraph_mm"]}
                />
                {errors["paragraph_mm"] && (
                  <FormHelperText error>
                    {errors["paragraph_mm"]}
                  </FormHelperText>
                )}
                {/* <FormHelperText error>{errors["paragraph_mm"]}</FormHelperText> */}
                {/* {errors.description && (
                  <FormHelperText error> {errors.description}</FormHelperText>
                )} */}
              </Box>
            </Box>
          </CardContent>

          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Create
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </>
  );
}
