import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Grid,
  Box,
  Typography,
  CardContent,
  CardActions,
  Button,
  Breadcrumbs,
  Modal,
  CardMedia,
} from "@mui/material";
import uploadService from "../../../services/upload";

import { instanceToken } from "../../../utils/constant";
import axios from "axios";
import emergencieService from "../../../services/emergencieService";
const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Emergencie = ({ homeAlert }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [emergencies, setEmergencies] = useState(null);
  const [openR, setOpenR] = useState(false);

  useEffect(() => {
    if (!emergencies) {
      fetchEmergencies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEmergencies = async () => {
    try {
      const res = await emergencieService.getOneEmergencies(
        instanceToken.token,
        id
      );
      setEmergencies(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  if (!emergencies) {
    return <em>Loading...</em>;
  }

  const handleOpenR = () => setOpenR(true);
  const handleCloseR = () => setOpenR(false);
  const handleRemove = async () => {
    try {
      await emergencieService.deleteEmergencies(instanceToken.token, id);
      homeAlert("Emergencies post have been removed.", false);
      navigate("/emergencies");
    } catch (error) {
      console.log(error);
      homeAlert("Error on server!", true);
    }
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/emergencies">
            Emergencies
          </Link>
          <Typography color="text.primary">Emergencies (ID - {id})</Typography>
        </Breadcrumbs>
      </div>
      <Container
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Card
          sx={{
            minWidth: "80vw",
            minHeight: "60vh",
            display: "flex",
            flexDirection: "column",
            // gridTemplateColumns: "2fr 3fr",
          }}
        >
          <Box
            sx={{
              maxWidth: "40vw",
              justifyContent: "center",
              ml: 33,
              borderRadius: 2,
              boxShadow: 2,
            }}
          >
            <CardMedia
              component="img"
              height="320"
              image={`data:image/png;base64,${emergencies.image_data}`}
            />
          </Box>
          <Box
            sx={{
              ml: 2,
              p: 2,
              // bgcolor: "#f7f7f5",
              borderRadius: 2,
            }}
          >
            {/* Name Eng */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Name Eng :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {emergencies.name_en}
                </Typography>
              </Grid>
            </Grid>

            {/* Name MM */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Name MM :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {emergencies.name_mm}
                </Typography>
              </Grid>
            </Grid>

            {/* Address Eng */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Address Eng:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {emergencies.address_en}
                </Typography>
              </Grid>
            </Grid>

            {/* Address MM */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Address MM:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {emergencies.address_mm}
                </Typography>
              </Grid>
            </Grid>

            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Phone :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {emergencies.phone}
                </Typography>
              </Grid>
            </Grid>

            {/* email */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Category :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {emergencies.category}
                </Typography>
              </Grid>
            </Grid>

            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Phone Note :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {emergencies.phone_note}
                </Typography>
              </Grid>
            </Grid>

            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Link :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  <a href={emergencies.link}>
                    {emergencies.link.substring(0, 10)}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <CardActions sx={{ gridColumn: "1/3", justifyContent: "end" }}>
            <Button
              sx={{ m: "1rem" }}
              variant="contained"
              color="error"
              onClick={handleOpenR}
            >
              Remove
            </Button>
            <Button
              size="small"
              variant="contained"
              component={Link}
              to={`/updateEmergencies/${emergencies.id}`}
            >
              Edit
            </Button>
          </CardActions>
        </Card>
      </Container>
      <Modal
        keepMounted
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button onClick={handleRemove}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Emergencie;
