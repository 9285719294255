import React, { useEffect, useState } from "react";
import claimFileService from "../../../services/claimFileService";
import { instanceToken } from "../../../utils/constant";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "axios";
import DirectionsIcon from "@mui/icons-material/Directions";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import {
  Breadcrumbs,
  Button,
  TableBody,
  Avatar,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TablePagination,
  InputBase,
} from "@mui/material";
import { CleaningServices, TableRowsRounded } from "@mui/icons-material";

export default function ClaimFiles() {
  const navigate = useNavigate();
  const { claim_form_id } = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [claimFiles, setClaimFiles] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchClaimFiles(rowsPerPage, offset);
  }, [offset, rowsPerPage]);

  useEffect(() => {
    fetchSearchByID(search);
  }, [search]);

  const fetchSearchByID = async (search) => {
    try {
      const res = await claimFileService.searchCliamFile(
        instanceToken.token,
        search
      );
      console.log(res);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const handleSearch = (e) => {
    setSearch(document.getElementById("search-by-id").value);
  };
  const fetchClaimFiles = async (limit, offset) => {
    try {
      const res = await claimFileService.getCliamFiles(
        instanceToken.token,
        limit,
        offset
      );

      parse(res.data);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const parse = (data) => {
    console.log(data);
    setClaimFiles(data.claim_files);
    setCount(data.count);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);

    setPage(0);
  };
  if (!claimFiles) {
    return <em>Loading</em>;
  }

  //Change data from String to object with JSON
  // if (typeof res.data.address !== "object") {
  //   try {
  //     let address = JSON.parse(res.data.address);
  //     user = { ...result.data.users_by_pk, address };
  //   } catch (e) {
  //     user = { ...result.data.users_by_pk };
  //   }
  // } else user = { ...result.data.users_by_pk, address: "-" };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 35,
        }}
      >
        <div role="presentation" style={{ marginBlockEnd: "10px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <span>Claim Form</span>
          </Breadcrumbs>
        </div>
        {/* search */}
        <div>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 350,
            }}
          >
            {/* Search Box */}

            <InputBase
              id="search-by-id"
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search By Claim Form ID"
              type="search"
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              color="primary"
              id="search-by-id"
              sx={{ p: "10px" }}
              aria-label="directions"
              value={search}
              onClick={handleSearch}
            >
              <DirectionsIcon />
            </IconButton>
          </Paper>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "end" }}>
        {/* <Button
          variant="contained"
          sx={{ my: 3 }}
          onClick={() => navigate("/createBanner")}
        >
          Create
        </Button> */}
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple-table">
          <TableHead>
            <TableRow className="thead">
              {/* <TableCell>ID</TableCell> */}
              {/* <TableCell>Image</TableCell> */}
              <TableCell sx={{ minWidth: 130 }}>Insurance ID</TableCell>

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {claimFiles
              .filter((f) => {
                return search === "" ? f : f.insurance_id.includes(search);
              })
              .map((row) => {
                console.log(row);
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* <TableCell component="th" scope="row">
                  {row.id}
                </TableCell> */}
                    {/* <TableCell>
                  <Avatar
                    alt="routine image"
                    src={`data:image/png;base64,${row.image_data}`}
                    width="56px"
                    height="56px"
                  />
                </TableCell> */}
                    <TableCell>{row.insurance_id}</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        component={Link}
                        color="secondary"
                        to={`/claimFiles/${row.insurance_id}`}
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
